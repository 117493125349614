<template>
  <WheelsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('wheel', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="pageSize"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </WheelsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'

import WHEELS_LIST from '#/graphql/wheels/list.gql'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const WheelsStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    WheelsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      where: {},
      pageSize: 10,
      page: 0,
      perPage: 10,
      wheels: [],
    }
  },
  computed: {
    columns() {
      const header = [
        {
          field: 'wheelId',
          key: 'wheelId',
          title: 'Wheel ID',
          align: 'left',
        },
        {
          field: 'asset',
          key: 'asset',
          title: 'Asset',
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row?.asset) {
              return '-'
            }
            const { id, __typename, name } = row.asset
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(wheel => {
        return {
          wheelId: wheel.id,
          asset: wheel.asset,
          rowKey: wheel.id,
          id: wheel.id,
          type: wheel.__typename,
        }
      })
      return tableData
    },
    skip() {
      return this.page * this.perPage
    },
  },
  methods: {
    reload() {
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
  },
  apollo: {
    models: {
      query: WHEELS_LIST,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'wheels', [])
        this.totalCount = get(data, 'aggregateWheel._count._all', 0)
      },
    },
  },
}
</script>
