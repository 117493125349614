<template>
  <TableMolecule>
    <VeTable
      ref="veTable"
      :columns="columns"
      :table-data.sync="tableData"
      :border-x="true"
      :border-y="true"
      :fixed-header="true"
      :row-style-option="rowStyleOption"
      :cell-style-option="cellStyleOption"
    />
  </TableMolecule>
</template>

<script>
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { buttonReset } from '@styles/mixins'
import { VeTable } from 'vue-easytable'
import 'vue-easytable/libs/theme-dark/index.css'

const TableMolecule = styled('div')`
  .empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    width: 100%;
    font-size: 16px;
    border-top: 0;
    border-bottom: 1px solid black;
  }

  .ve-table .ve-table-container table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td.table-body-cell-actions {
    padding: 0;
  }
  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 18px;
    grid-template-rows: 18px;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    button,
    a {
      ${buttonReset}
      display: inline-block;
      width: 18px;
      height: 18px;
    }
  }

  .ve-table .ve-table-container {
    table.ve-table-content {
      thead.ve-table-header .ve-table-header-tr .ve-table-header-th .ve-table-filter .ve-table-filter-icon {
        color: ${({ theme }) => theme.colors.white};
        &:hover {
          color: ${({ theme }) => theme.colors.pastelBlue};
        }
      }
    }
  }
  //Table Filter PopOver
  .ve-dropdown-popper {
    .ve-dropdown-operation .ve-dropdown-operation-item {
      color: ${({ theme }) => theme.colors.white};
      &:not(.ve-dropdown-filter-disable):hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    .ve-dropdown-dd .ve-dropdown-items .ve-dropdown-items-warpper .ve-dropdown-items-multiple {
      > span {
        color: ${({ theme }) => theme.colors.white};
      }
      &:hover {
        background: ${({ theme }) => chroma(theme.colors.white).alpha(0.2).css()};
        backdrop-filter: blur(7px);
      }
    }
  }
`

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
  components: {
    VeTable,
    TableMolecule,
  },
  data() {
    return {
      rowStyleOption: {
        clickHighlight: true,
      },
      cellStyleOption: {
        bodyCellClass: ({ column }) => {
          if (column.field === 'actions') {
            return 'table-body-cell-actions'
          }
        },
      },
    }
  },
}
</script>
