<template>
  <PanelMolecule :title="title" :errors="errors" :count="count" :hasMargin="hasMargin" :useDarkStyle="useDarkStyle">
    <template v-if="hasHeaderSlot" slot="header">
      <slot name="header" />
    </template>
    <template slot="main">
      <TalpaLoaderWrapper v-if="isLoading" class="loader" />
      <TableMolecule v-else-if="!isLoading && columns.length > 0" :columns="columns" :tableData="tableData" />
      <div v-if="rows.length === 0" class="empty-data">{{ $tc('messages.noData') }}</div>
    </template>
    <template slot="footer">
      <FooterStyled>
        <div class="actions">
          <button v-if="createRouteName" @click="create">
            <PlusIcon />
          </button>
          <button @click="$emit('reload')">
            <RefreshCcwIcon />
          </button>
          <button v-if="hasClearSearch" @click="$emit('clear-search')">
            <XIcon />
          </button>
        </div>
        <div class="table-pagination">
          <ve-pagination
            :total="count"
            :page-index="pageIndex"
            :page-size="pageSize"
            :layout="['pager']"
            @on-page-number-change="$emit('pageNumberChange', $event)"
          />
        </div>
      </FooterStyled>
    </template>
  </PanelMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import gql from 'graphql-tag'
import { get } from 'lodash'
import { FlashMessages } from '@common/singletons'
import { getRolesFromToken } from '@common/utils'
import { TalpaLoaderWrapper } from '@common/components'
import { buttonReset, flexCenter } from '@styles/mixins'
import { PlusIcon, RefreshCcwIcon, XIcon } from 'vue-feather-icons'
import { VePagination } from 'vue-easytable'
import 'vue-easytable/libs/theme-dark/index.css'

import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'
import TableMolecule from '@/components/Atomic/Molecules/TableMolecule'

const FooterStyled = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content;
  grid-gap: 0.25rem;
  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-template-rows: 1fr;
    align-items: center;
    grid-gap: 0.25rem;
    > button {
      ${buttonReset}
      ${flexCenter}
      width: 2rem;
      height: 2rem;
      padding: 0;
      > .feather {
        color: ${p => p.theme.colors.pastelBlue};
        &:hover {
          color: ${p => chroma(p.theme.colors.pastelBlue).alpha(0.5).css()};
        }
      }
    }
  }

  .table-pagination {
    justify-self: end;
    margin-top: 0.25rem;
    text-align: right;
    > .ve-pagination {
      background: none;
      .ve-pagination-li {
        background: ${({ theme }) => theme.colors.archonBlack};
        border-color: ${({ theme }) => theme.colors.archonBlue};
        > a {
          color: ${({ theme }) => theme.colors.archonBlue};
          font-weight: normal;
        }
        &.ve-pagination-li-active {
          border-color: ${({ theme }) => theme.colors.archonBlack};
          > a {
            color: white;
            font-weight: normal;
            border-bottom: 1px solid white;
          }
        }
      }
      .ve-pagination-goto .ve-pagination-goto-input {
        color: white;
      }
      .ve-pagination-jump-prev,
      .ve-pagination-jump-next {
        &::after {
          color: ${({ theme }) => theme.colors.archonBlue};
        }
      }
    }
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    pageIndex: {
      type: Number,
      required: true,
      default: 10,
    },
    pageSize: {
      type: Number,
      required: true,
      default: 0,
    },
    createRouteName: {
      type: String,
      required: false,
    },
    selectedRow: {
      type: Object,
      required: false,
    },
    highlightedRowKey: {
      type: String,
    },
    hasClearSearch: {
      type: Boolean,
      default: false,
    },
    useDarkStyle: {
      type: Boolean,
      default: false,
    },
    hasMargin: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
    },
  },
  components: {
    VePagination,
    TableMolecule,
    PanelMolecule,
    // CountCircle,
    TalpaLoaderWrapper,
    PlusIcon,
    RefreshCcwIcon,
    XIcon,
    FooterStyled,
  },
  watch: {
    rows: {
      handler() {
        this.tableData = this.rows
      },
      immediate: true,
    },
    selectedRow: {
      handler(row) {
        this.confirmDelete(row)
      },
    },
    highlightedRowKey: {
      handler(rowKey) {
        if (rowKey) {
          this.$refs.veTable.setHighlightRow({ rowKey })
        }
      },
    },
  },
  data() {
    return {
      searchQuery: '',
      tableData: [],
    }
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    isArchon() {
      return this.userRoles.includes('archon')
    },
  },
  methods: {
    create() {
      this.$router.push({ name: this.createRouteName })
    },
    confirmDelete(model) {
      if (model && model.__typename === 'User') {
        if (get(model, 'roles', []).includes('archon') && !this.isArchon) {
          FlashMessages.$emit('error', new Error(`Only an Archon can delete a Archon`))
          return
        }
      }

      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: model.type,
          instance: model,
          labelKey: 'name',
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async delete(model) {
      if (model && model.type === 'User') {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              delete${model.type}(where: {id: $id})
            }
          `,
          variables: {
            id: model.id,
          },
        })
        if (res) {
          this.$root.$emit('closeOverlay')
          this.$router.push({ name: 'users' })
          this.$emit('reload')
        }
      } else {
        const res = await this.$apollo.mutate({
          mutation: gql`
          mutation ($id: ID!) {
            delete${model.type}(where: { id: $id }) {
              id
            }
          }
        `,
          variables: {
            id: model.id,
          },
        })
        if (res) {
          this.$root.$emit('closeOverlay')
          this.$emit('reload')
        }
      }
    },
  },
}
</script>
