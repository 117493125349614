var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PanelMolecule', {
    attrs: {
      "title": _vm.title,
      "errors": _vm.errors,
      "count": _vm.count,
      "hasMargin": _vm.hasMargin,
      "useDarkStyle": _vm.useDarkStyle
    }
  }, [_vm.hasHeaderSlot ? _c('template', {
    slot: "header"
  }, [_vm._t("header")], 2) : _vm._e(), _c('template', {
    slot: "main"
  }, [_vm.isLoading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : !_vm.isLoading && _vm.columns.length > 0 ? _c('TableMolecule', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData
    }
  }) : _vm._e(), _vm.rows.length === 0 ? _c('div', {
    staticClass: "empty-data"
  }, [_vm._v(_vm._s(_vm.$tc('messages.noData')))]) : _vm._e()], 1), _c('template', {
    slot: "footer"
  }, [_c('FooterStyled', [_c('div', {
    staticClass: "actions"
  }, [_vm.createRouteName ? _c('button', {
    on: {
      "click": _vm.create
    }
  }, [_c('PlusIcon')], 1) : _vm._e(), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('reload');
      }
    }
  }, [_c('RefreshCcwIcon')], 1), _vm.hasClearSearch ? _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('clear-search');
      }
    }
  }, [_c('XIcon')], 1) : _vm._e()]), _c('div', {
    staticClass: "table-pagination"
  }, [_c('ve-pagination', {
    attrs: {
      "total": _vm.count,
      "page-index": _vm.pageIndex,
      "page-size": _vm.pageSize,
      "layout": ['pager']
    },
    on: {
      "on-page-number-change": function onPageNumberChange($event) {
        return _vm.$emit('pageNumberChange', $event);
      }
    }
  })], 1)])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }