var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableMolecule', [_c('VeTable', {
    ref: "veTable",
    attrs: {
      "columns": _vm.columns,
      "table-data": _vm.tableData,
      "border-x": true,
      "border-y": true,
      "fixed-header": true,
      "row-style-option": _vm.rowStyleOption,
      "cell-style-option": _vm.cellStyleOption
    },
    on: {
      "update:tableData": function updateTableData($event) {
        _vm.tableData = $event;
      },
      "update:table-data": function updateTableData($event) {
        _vm.tableData = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }